import { memo } from 'react';
import classNames from 'classnames';
import { Container } from '@swordhealth/ui-corporate';
import Link from 'next/link';
import { getImageProps } from 'next/image';

import useButtonTracking from '@/utils/useButtonTracking';
import { getImgData } from '@/utils/images';

import { Button } from '@/components/core/Button';
import { Paragraph, Section, Title } from '@/components/core';

import { Wrapper, StyledSection, StyledLabel, StyledLogo, ExtraLabel } from './styles';
import theme from '@/utils/styles-variables';
import MarkdownRichText from '@/components/shared/MarkdownRichText';

const HeaderBackground = ({
  content,
  contentAlign = 'left',
  titleSize = '3xl',
  isLight = false,
  showLogo = false,
  ...rest
}) => {
  const { upperLabel, title, subtitle, href, image, imageTablet, imageMobile, extraLabel } =
    content;
  const trackClick = useButtonTracking({ label: href?.label, url: href?.href, location: 'header' });

  if (!content) return null;

  const imageData = getImgData(image);
  const imageDataTablet = getImgData(imageTablet);
  const imageDataMobile = getImgData(imageMobile);

  const common = {
    alt: imageData?.alternativeText || '',
    fill: true,
    sizes: '100vw',
    priority: true,
    loading: 'eager',
  };
  const {
    props: { srcSet: tablet },
  } = getImageProps({
    ...common,
    quality: 80,
    src: imageDataTablet?.url,
  });
  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...common,
    src: imageDataMobile?.url,
  });
  const { props: desktop } = getImageProps({
    ...common,
    src: imageData?.url,
  });

  return (
    <Section as="header" {...rest}>
      <Wrapper
        className={classNames({
          'header-light': isLight,
          'header-dark': !isLight,
          'header-center': contentAlign === 'center',
          'header-left': contentAlign === 'left',
          'header-with-extra-label': !!extraLabel,
        })}
      >
        <picture className="bg-image">
          {imageDataTablet && (
            <source
              media={`(min-width: ${theme.breakpoints.min_sm}px) and (max-width: ${theme.breakpoints.max_lg}px)`}
              srcSet={tablet}
            />
          )}
          {imageDataMobile && (
            <source media={`(max-width: ${theme.breakpoints.max_sm}px)`} srcSet={mobile} />
          )}
          <img {...desktop} />
        </picture>
        <Container>
          <StyledSection spacerTop sm="40" smTop="40" xs="40" xsTop="40" md="40" mdTop="40">
            {showLogo && (
              <Link href="/" target="_blank" rel="noopener noreferrer">
                <StyledLogo
                  priority
                  src="/assets/logo/logo__colored.svg"
                  alt="Sword Health"
                  height={28}
                  width={114}
                />
              </Link>
            )}
            {upperLabel && <StyledLabel>{upperLabel}</StyledLabel>}
            <Title as="h1" size={titleSize}>
              <MarkdownRichText>{title}</MarkdownRichText>
            </Title>
            {subtitle && (
              <Paragraph forwardedAs="h2" markdownProps={{ elements: ['a'] }}>
                {subtitle}
              </Paragraph>
            )}
            {href?.label && (
              <Button href={href?.href} onClick={trackClick}>
                {href.label}
              </Button>
            )}
          </StyledSection>
        </Container>
        {extraLabel && (
          <ExtraLabel>
            <Paragraph>{extraLabel}</Paragraph>
          </ExtraLabel>
        )}
      </Wrapper>
    </Section>
  );
};

export default memo(HeaderBackground);
