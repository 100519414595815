const COOKIES_PATH = '/';
const COOKIES_DOMAIN = process.env.NEXT_PUBLIC_COOKIES_DOMAIN || '';

export default function getCookieData() {
  const expireDate = new Date();
  expireDate.setMonth(expireDate.getMonth() + 1);

  return {
    path: COOKIES_PATH,
    domain: COOKIES_DOMAIN,
    expires: expireDate,
  };
}
