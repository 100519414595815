'use client';

import { useState, useEffect } from 'react';

const useReducedMotion = () => {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    setPrefersReducedMotion(mediaQuery.matches);

    const handleReduceMotionChange = (event) => {
      setPrefersReducedMotion(event.matches);
    };

    mediaQuery.addEventListener('change', handleReduceMotionChange);

    return () => {
      mediaQuery.removeEventListener('change', handleReduceMotionChange);
    };
  }, []);

  return prefersReducedMotion;
};

export default useReducedMotion;
