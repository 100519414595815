import React, { useMemo } from 'react';
import Link from 'next/link';
import { Button as UiButton } from '@swordhealth/ui-corporate';
import useUrlAttributes from '@/utils/useUrlAttributes';

const Button = React.forwardRef(
  (
    { as, children, variant, size, href, onClick, className, type, loading, rounded, ...rest },
    ref,
  ) => {
    const urlAttributes = useUrlAttributes(href || '');
    const isInternalLink = useMemo(
      () => href && urlAttributes?.target !== '_blank',
      [href, urlAttributes],
    );
    const Wrapper = useMemo(() => (isInternalLink ? Link : React.Fragment), [isInternalLink]);
    const wrapperProps = useMemo(
      () => (isInternalLink ? { href, passHref: true, legacyBehavior: true } : {}),
      [href, isInternalLink],
    );

    return (
      <Wrapper {...wrapperProps}>
        <UiButton
          ref={ref}
          variant={variant}
          size={size}
          {...(href ? urlAttributes : { as })}
          onClick={onClick}
          className={className}
          type={type}
          loading={loading}
          rounded={rounded}
          {...rest}
        >
          {children}
        </UiButton>
      </Wrapper>
    );
  },
);

Button.displayName = 'Button';

export default Button;
