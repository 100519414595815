'use client';

import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Banner from '@/components/core/Banner';
import Navigation from '@/components/core/Navigation';
import { NavigationProvider, useNavigationContext } from '@/contexts/navigation';
import Footer from '../Footer';
import FooterLanding from '../FooterLanding/FooterLanding';

const LayoutInner = ({
  children,
  bannerInfo,
  dynamicNavbarLayout,
  withoutNavigation,
  withoutMenu,
  withoutFooter,
  isLanding = false,
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
    initialInView: true,
    rootMargin: withoutNavigation ? '-80px' : '0px',
  });
  const { setScrolled } = useNavigationContext();

  useEffect(() => {
    if (withoutMenu) {
      return;
    }

    setScrolled(!inView);
  }, [inView, setScrolled, withoutMenu]);

  return (
    <>
      <div className="banner-observer" ref={ref}>
        {bannerInfo?.active && <Banner bannerInfo={bannerInfo} />}
      </div>

      {!withoutNavigation && (
        <Navigation
          fixed={!withoutMenu && !inView}
          dynamicNavbarLayout={dynamicNavbarLayout}
          withoutMenu={withoutMenu}
          bannerActive={bannerInfo?.active}
        />
      )}

      <main>{children}</main>
      {!withoutFooter && <Footer />}
      {isLanding && <FooterLanding />}
    </>
  );
};

const Layout = ({ enrollUrl, demoUrl, ...props }) => {
  useEffect(() => {
    function setWindowWidth() {
      document.documentElement.style.setProperty(
        '--window-width',
        `${document.documentElement.clientWidth}px`,
      );
    }
    setWindowWidth();

    window.addEventListener('resize', () => setWindowWidth());

    return () => {
      window.removeEventListener('resize', () => setWindowWidth());
    };
  }, []);

  return (
    <NavigationProvider
      enrollUrl={
        enrollUrl || 'https://meet.swordhealth.com/?utm_medium=referral&utm_source=sword_website'
      }
      demoUrl={demoUrl || '/request-demo/platform'}
    >
      <LayoutInner {...props} />
    </NavigationProvider>
  );
};

export default Layout;
