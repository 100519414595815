'use client';

import { useCallback } from 'react';
import { usePathname } from 'next/navigation';
import { trackNavigation } from './track';

type UseButtonTrackingProps = {
  label: string;
  location?: string;
  url?: string;
};

export default function useButtonTracking({
  label,
  location,
  url,
}: UseButtonTrackingProps): () => void {
  const pathname = usePathname();

  return useCallback(() => {
    trackNavigation({
      label,
      location,
      pathname,
      url,
    });
  }, [label, location, pathname, url]);
}
