import { memo } from 'react';
import { useField } from 'formik';

import FileUpload from './input/FileUpload';

import { Input, CheckboxGroup, RadioGroup, TextArea, Dropdown } from '@swordhealth/ui-corporate';
import HelpLabel from './input/HelpLabel';
import styled from 'styled-components';

function validateField({ customErrorMessage, value, validation = 'text', required = false }) {
  let errorMessage;
  const stringValue = value?.toString() || '';
  const hasValue = stringValue.length > 0 && value;

  if (required && !hasValue) {
    return customErrorMessage || 'This field is required';
  }

  if (!hasValue) {
    return errorMessage;
  }

  switch (validation) {
    case 'email': {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        errorMessage = 'Enter a correct email';
      }

      break;
    }
    case 'url': {
      if (
        !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i.test(
          value,
        )
      ) {
        errorMessage = 'Enter a correct url';
      }

      break;
    }
    case 'phone': {
      if (!/^\+[1-9]{1}[0-9]{3,14}$/.test(stringValue)) {
        errorMessage = 'Enter a phone number in the international format (eg: +15555555555)';
      }

      break;
    }
    default: {
      break;
    }
  }

  return errorMessage;
}

const StyledTextArea = styled(TextArea)`
  textarea {
    height: 86px;
  }
`;

const Field = ({ type, onChange, ...props }) => {
  const [{ value, ...formikField }, meta, helpers] = useField({
    ...props,
    validate: (value) =>
      validateField({ value, validation: props.validation || props.type, ...props }),
  });

  const fieldProps = {
    ...props,
    suffix: props.required ? null : (
      <HelpLabel size="xs" aria-hidden="true" className="field-suffix">
        optional
      </HelpLabel>
    ),
    errorMessage: meta.error && meta.touched ? meta.error : null,
  };

  switch (type) {
    case 'dropdown':
      return (
        <Dropdown
          {...fieldProps}
          {...formikField}
          fullWidth
          defaultValue={value}
          onChange={(value) => {
            helpers.setValue(value);

            if (onChange) {
              onChange(value, helpers);
            }
          }}
        />
      );
    case 'multiple-select':
      return (
        <CheckboxGroup
          {...fieldProps}
          {...formikField}
          defaultValue={value}
          onChange={(value) => {
            helpers.setValue(value);

            if (onChange) {
              onChange(value);
            }
          }}
        />
      );
    case 'multiple-choice':
      return (
        <RadioGroup
          {...fieldProps}
          {...formikField}
          defaultValue={value}
          onChange={(value) => {
            helpers.setValue(value);

            if (onChange) {
              onChange(value);
            }
          }}
        />
      );
    case 'file':
    case 'file-upload':
      return <FileUpload {...fieldProps} field={{ ...formikField, ...helpers }} />;
    case 'textarea':
      return (
        <StyledTextArea
          {...fieldProps}
          {...formikField}
          defaultValue={value}
          onChange={(value) => {
            helpers.setValue(value);

            if (onChange) {
              onChange(value);
            }
          }}
        />
      );
    default:
      return (
        <Input
          {...fieldProps}
          {...formikField}
          defaultValue={value}
          onChange={(value) => {
            helpers.setValue(value);

            if (onChange) {
              onChange(value);
            }
          }}
          type={type || 'text'}
        />
      );
  }
};

export default memo(Field);
