import styled from 'styled-components';

const UpperText = styled.div`
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  color: ${(props) => props.theme.colors.grey.default};
  font-family: ${(props) => props.theme.font.family.heading};
  padding-bottom: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 24px;
  }
`;

export default UpperText;
