import styled from 'styled-components';

const Label = styled.div`
  font-size: 16px;
  line-height: 28px;
  opacity: 0.8;
  padding-bottom: 32px;

  p {
    margin-bottom: 8px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 24px;
  }
`;

export default Label;
