import styled from 'styled-components';

import LogoSVG from '@/public/assets/logo/logo__colored.svg';

const Logo = styled(LogoSVG)`
  width: 99px;
  height: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 8px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: none;
  }
`;

export default Logo;
