import Link from 'next/link';
import styled from 'styled-components';

import useButtonTracking from '@/utils/useButtonTracking';

const StyledLink = styled.button`
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: fit-content;
  transition: all 0.3s ease;
  & + & {
    margin-top: 15px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const SubLink = ({ name, href, newTab, className, ...rest }) => {
  const trackClick = useButtonTracking({ label: name, url: href, location: 'footer' });

  return (
    <StyledLink
      {...rest}
      data-testid={`sublink-${name}`}
      className={className}
      href={href}
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noreferrer' : undefined}
      as={href ? Link : 'button'}
      onClick={href ? trackClick : undefined}
    >
      {name}
    </StyledLink>
  );
};

export default SubLink;
