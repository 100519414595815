import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

export const ErrorBoxWrapper = styled.div`
  overflow: hidden;
  margin-bottom: 15px;
`;

const ErrorBox = styled.div`
  border-radius: 4px;
  background-color: ${(props) => rgba(props.theme.colors.inputs.error, 0.1)};

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation-name: fade-in;
  animation-duration: 1s;
`;

const ErrorBoxContent = styled.div`
  padding: 15px 19px 15px 46px;
  display: inline-flex;
  position: relative;
`;

const Icon = styled.img`
  position: absolute;
  content: '';
  left: 17px;
  top: 50%;
  transform: translateY(-50%);
  height: 15px;
  width: 15px;
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.inputs.error};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
`;

const ErrorMessageComponent = ({ className, label, hasError }) => {
  if (!hasError) {
    return null;
  }

  return (
    <ErrorBoxWrapper className={className}>
      <ErrorBox>
        <ErrorBoxContent>
          <Icon src="/assets/icons/ico_warning.svg" alt="Error" />
          <Label>{label}</Label>
        </ErrorBoxContent>
      </ErrorBox>
    </ErrorBoxWrapper>
  );
};

export default ErrorMessageComponent;
