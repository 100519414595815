import { memo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled from 'styled-components';

import { Paragraph } from '@/components/core';
import { Button } from '@/components/core/Button';

const CenteredContent = styled.div`
  display: grid;
  gap: 32px;
  justify-items: center;
  text-align: center;
`;

const Buttons = styled.div`
  display: grid;
  gap: 8px;
  justify-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    grid-auto-flow: column;
  }
`;

const Alert = ({ primaryButton, secondaryButton, content, image, onClose }) => (
  <CenteredContent>
    <Image {...image} alt="" priority loading="eager" />
    <Paragraph markdownProps={{ elements: ['p', 'h3', 'h4'] }}>{content}</Paragraph>
    <Buttons>
      {primaryButton && (
        <Button
          variant="primary"
          onClick={primaryButton.href ? null : onClose}
          href={primaryButton.href}
          as={primaryButton.href ? Link : null}
        >
          {primaryButton.label}
        </Button>
      )}
      {secondaryButton && (
        <Button
          variant="secondary"
          onClick={secondaryButton.href ? null : onClose}
          href={secondaryButton.href}
          as={secondaryButton.href ? Link : null}
        >
          {secondaryButton.label}
        </Button>
      )}
    </Buttons>
  </CenteredContent>
);

export default memo(Alert);
