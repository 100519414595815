import styled from 'styled-components';
import { Col } from '@/components/core/flexbox';

const SocialCol = styled(Col)`
  width: 201px;
  margin-top: 88px;
  line-height: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 78px;
    margin-right: 0;
    width: unset;
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 47px;
    justify-content: unset;
  }
`;

export default SocialCol;
