import styled from 'styled-components';

import { Container } from '@/components/core/flexbox';

import SubLink from './SubLink';

export const LEGAL = [
  {
    name: 'Terms and Conditions',
    href: '/legal/terms-and-conditions',
  },
  {
    name: 'Consent to Telehealth Services',
    href: '/legal/consent-to-telehealth-services',
  },
  {
    name: 'HIPAA Notice of Privacy Practices',
    href: '/legal/hipaa-notice-of-privacy-practices',
  },
  {
    name: 'Privacy Statement',
    href: '/legal/privacy-statement',
  },
  {
    name: 'Non-Discrimination',
    href: '/legal/notice-of-non-discrimination',
  },
  {
    name: 'Responsible Disclosure',
    href: '/security/responsible-disclosure',
  },
  {
    name: 'Sitemap',
    href: '/sitemap',
  },
  {
    name: 'Status',
    href: 'https://status.swordhealth.com/',
  },
];

const Legal = () => {
  return (
    <Container>
      <List>
        {LEGAL.map((item) => (
          <StyledLink key={item.name} name={item.name} href={item.href} newTab />
        ))}
        <StyledLink ez-show-preferences="true" name="Cookie Preferences" />
      </List>
    </Container>
  );
};

export default Legal;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    text-align: center;
  }
`;

const StyledLink = styled(SubLink)`
  color: ${(props) => props.theme.colors.grey.default};
  font-size: 12px;
  line-height: 24px;
  margin-top: 0;
  & + & {
    margin-top: 0 !important;
  }
`;
