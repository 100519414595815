// TODO
// After replacing Neutral Button with Pills from ui-corporate,
// this component can be deleted

import styled from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
`;

const Loading = ({ loading, loadingColor }) => {
  if (!loading) return null;
  return (
    <LoaderWrapper>
      <PulseLoader color={loadingColor} size={8} />
    </LoaderWrapper>
  );
};

export default Loading;
