import React from 'react';
import Link from 'next/link';

import { Container } from '@/components/core/flexbox';
import { Wrapper, Row, Text, Logo } from './styles';

const FooterLanding = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Wrapper>
      <Container>
        <Row>
          <Text>© {currentYear} Sword Health, Inc. All Rights Reserved</Text>

          <Link href="https://swordhealth.com/" target="_blank">
            <Logo />
          </Link>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default FooterLanding;
