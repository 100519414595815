import ReactSlider from 'react-slick';

import SliderWrapper from './Wrapper';

const Slider = ({ children, length, infinite = false }) => {
  const settings = {
    infinite,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  return (
    <SliderWrapper dots={!length || length > 1}>
      <ReactSlider {...settings}>{children}</ReactSlider>
    </SliderWrapper>
  );
};

export default Slider;
