import styled from 'styled-components';
import Image from 'next/image';

import { Container } from '@/components/core/flexbox';

const Sponsors = () => {
  return (
    <Wrapper>
      <Container>
        <Icons>
          <Image
            src="/assets/footer/portugal_2020.svg"
            alt="Portugal 2020"
            width="65"
            height="20"
          />
          <Image src="/assets/footer/norte_2020.svg" alt="Norte 2020" width="126" height="20" />
          <Image
            src="/assets/footer/uniao_europeia.svg"
            alt="European Union"
            width="82"
            height="20"
          />
          <Image
            src="/assets/footer/prr.svg"
            alt="Plano de Recuperação e Resiliência"
            width="58"
            height="20"
          />
          <Image
            src="/assets/footer/republica-portuguesa-2023.svg"
            alt="República Portuguesa"
            width="132"
            height="23"
          />
          <Image
            src="/assets/footer/next-gen-eu.svg"
            alt="Next Generation EU"
            width="80"
            height="20"
          />
        </Icons>
      </Container>
    </Wrapper>
  );
};

export default Sponsors;

const Wrapper = styled.div`
  background: #edeae8;
  opacity: 0.7;
`;

const Icons = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  column-gap: 18px;
  row-gap: 24px;
  flex-wrap: wrap;
  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    justify-content: center;
  }
`;
