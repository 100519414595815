import { SEOMetadata } from '@/config/seo-meta';

export function buildSeoDescription(jobContent) {
  if (!jobContent) return '';

  const { text, categories } = jobContent;
  let string = `Apply for our open position as a ${text}. Join our ${categories.team || categories.department} team`;
  if (!categories.location || categories.location === 'remote') {
    string = string.concat('.');
  } else {
    string = string.concat(` in ${categories.location}`);
  }
  return string;
}

function mapEmploymentType(type) {
  switch (type) {
    case 'Full Time':
      return 'FULL_TIME';
    case 'Contract' || 'Contract (Third Party)':
      return 'CONTRACTOR';
    case 'Fixed Term 1 year':
      return 'TEMPORARY';
    case 'Internship':
      return 'INTERN';
    case 'Part Time':
      return 'PART_TIME';
    default:
      return 'OTHER';
  }
}

const jobLocationList = [
  {
    label: 'Brazil',
    country: 'Brazil',
  },
  {
    label: 'Ireland',
    country: 'Ireland',
  },
  {
    label: 'Lisboa',
    country: 'PT',
    region: 'Lisbon',
  },
  {
    label: 'New York',
    country: 'USA',
    region: 'New York',
  },
  {
    label: 'Porto',
    country: 'Portugal',
    region: 'Porto',
  },
  {
    label: 'Portugal',
    country: 'Portugal',
  },
  {
    label: 'Salt Lake City',
    country: 'USA',
    region: 'Utah',
    locality: 'Salt Lake City',
  },
  {
    label: 'Salt Lake City, Utah',
    country: 'USA',
    region: 'Utah',
    locality: 'Salt Lake City',
  },
  {
    label: 'UK',
    country: 'UK',
  },
  {
    label: 'United States',
    country: 'USA',
  },
];

function findMatchingObjects(strings, objects) {
  return strings.map((str) => objects.find((obj) => obj.label === str)).filter(Boolean);
}

function mapJobLocationListToSeoObjects(allLocations) {
  const filteredLocations = findMatchingObjects(allLocations, jobLocationList);

  const seoLocations = [];

  filteredLocations.forEach((obj) => {
    const seoObj = {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
      },
    };

    if (obj.country) {
      seoObj.address.addressCountry = obj.country;
    }
    if (obj.region) {
      seoObj.address.addressRegion = obj.region;
    }
    if (obj.locality) {
      seoObj.address.addressLocality = obj.locality;
    }

    seoLocations.push(seoObj);
  });

  return seoLocations;
}

const JobPostingSEO = (data) => {
  const {
    createdAt,
    content: { description },
    workplaceType,
    categories: { allLocations, commitment },
    text,
  } = data;

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'JobPosting',
    datePosted: new Date(createdAt).toISOString(),
    description: description || undefined,
    hiringOrganization: {
      '@type': 'Organization',
      name: 'Sword Health',
      sameAs: 'https://swordhealth.com',
      logo: SEOMetadata.logo,
    },
    jobLocation:
      workplaceType !== 'remote' ? mapJobLocationListToSeoObjects(allLocations) : undefined,
    title: text,
    applicantLocationRequirements:
      workplaceType === 'remote' ? { '@type': 'Country', name: 'PT' } : undefined,
    directApply: true,
    employmentType: mapEmploymentType(commitment),
    jobLocationType: workplaceType === 'remote' ? 'TELECOMMUTE' : undefined,
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
};

export default JobPostingSEO;
