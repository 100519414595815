'use client';

import { useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';

import Sponsors from '@/components/core/Footer/Sponsors';
import Projects from '@/components/core/Footer/Projects';

const Portugal2020 = () => {
  const [isVisible, setVisible] = useState(true);

  useEffect(() => {
    async function getLocation() {
      try {
        const response = await fetch('/api/ip-location');
        const data = await response.json();

        setVisible(data?.country === 'PT');
      } catch (error) {
        captureException(error);
      }
    }

    getLocation();
  }, []);

  if (!isVisible) return null;

  return (
    <>
      <Sponsors />
      <Projects />
    </>
  );
};

export default Portugal2020;
