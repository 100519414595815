import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  content: '';
  bottom: -48px;
  color: #a4aab6;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    bottom: -64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    bottom: -40px;
  }
`;

export default Wrapper;
