import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 88px;
  background-color: ${(props) => props.theme.colors.neutral.default};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 108px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 179px;
  }
`;

export default Wrapper;
