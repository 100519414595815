'use client';

import { EventJsonLd } from 'next-seo';
import { usePathname } from 'next/navigation';

const CommercialSEO = ({ seo }) => {
  const pathname = usePathname();

  return (
    <EventJsonLd
      {...seo}
      url={`${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}${pathname}`}
      images={[seo.image.url]}
      organizer={{
        type: 'Organization',
        name: 'Sword Health',
        url: `${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}`,
      }}
      eventStatus="EventScheduled"
    />
  );
};

export default CommercialSEO;
