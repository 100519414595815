import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '../Item';

import { Container } from '@/components/core/flexbox';

// eslint-disable-next-line react/display-name
const Dropdown = forwardRef(({ children, open, ...rest }, ref) => {
  return (
    <Wrapper ref={ref} open={open} {...rest}>
      <CustomContainer>
        <Inner data-testid="dropdown">{children}</Inner>
      </CustomContainer>
    </Wrapper>
  );
});

export default Dropdown;

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    background-color: #fff;
    padding: 41px 0;
    box-shadow: 0 11px 41px rgba(31, 34, 44, 0.04);
    transition: all 0.3s ease-in-out;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    border-top: 2px solid rgba(47, 44, 42, 0.1);

    ${(props) =>
      props.open &&
      css`
        opacity: 1;
        z-index: 100000;
        pointer-events: all;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    opacity: 0;
    height: 0;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;

    ${(props) =>
      props.open &&
      css`
        opacity: 1;
        height: 100%;
        pointer-events: all;

        ${Box} {
          transform: translateX(0);
          opacity: 1;
        }
      `};
  }
`;

const Inner = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(128px, auto);
  column-gap: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
  }
`;

const CustomContainer = styled(Container)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 0;
  }
`;

export { Wrapper as DropdownBox };
