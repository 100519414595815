import styled from 'styled-components';
import MarkdownRichText from './MarkdownRichText';

import { Row, Container } from '@/components/core/flexbox';

const FOOTNOTE_PREFIX = 'footnote';

const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.neutral.default};

  h2 {
    padding: 0;
  }
`;

const InnerWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(31, 34, 44, 0.1);
`;

const Title = styled.h3`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  padding-bottom: 16px;
  color: ${(props) => props.theme.colors.grey.dark};
`;

const Number = styled.span`
  color: ${(props) => props.theme.colors.primary.default};
  width: 1em;
`;

const Text = styled(MarkdownRichText)`
  width: calc(100% - 14px);
  margin-left: -14px;
  text-indent: ${({ doubleSpace }) => (doubleSpace ? '1.5em' : '1em')};
  color: ${(props) => props.theme.colors.grey.default};
  word-wrap: break-word;

  a {
    text-decoration: underline;
  }
`;

const CustomRow = styled(Row)`
  margin-bottom: 8px;
  font-size: 14px;
`;

export function getFootnoteId(id) {
  return `${FOOTNOTE_PREFIX}-${id}`;
}

const Footnotes = ({ footNotes }) => {
  return (
    <Wrapper id="footnotes">
      <Container>
        <InnerWrapper>
          <Title>Footnotes</Title>
          {footNotes?.footnote?.map((item, index) => (
            <CustomRow key={item.label} id={getFootnoteId(index + 1)}>
              <Number>{index + 1}</Number>
              <Text elements={['p', 'a']} doubleSpace={index >= 9}>
                {item.label}
              </Text>
            </CustomRow>
          ))}
        </InnerWrapper>
      </Container>
    </Wrapper>
  );
};

export default Footnotes;
