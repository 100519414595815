export default function getImgSrcWorkaround(src) {
  try {
    // if image src is a valid url return as is
    new URL(src);

    return src;
  } catch {
    if (process.env.NEXT_PUBLIC_LOCALHOST_IMAGE_DOMAIN) {
      return process.env.NEXT_PUBLIC_LOCALHOST_IMAGE_DOMAIN + src;
    }

    return src;
  }
}

export function getImgData(image) {
  if (!image) {
    return null;
  }

  if (image?.data?.attributes) {
    return { image, ...image.data.attributes };
  }

  if (image?.attributes) {
    return { image, ...image.attributes };
  }

  return image;
}
