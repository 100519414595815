'use client';

import React, { useContext, useState, createContext } from 'react';

const NavigationContext = createContext();

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationProvider = ({ enrollUrl, demoUrl, children, opaque = false }) => {
  const [scrolled, setScrolled] = useState(opaque);
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <NavigationContext.Provider
      value={{
        scrolled,
        setScrolled,
        isOpen,
        setIsOpen,
        hover,
        setHover,
        enrollUrl,
        demoUrl,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
