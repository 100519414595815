import styled from 'styled-components';
import LogoSword from '@/public/assets/logo/logo-sword-red-white.svg';

export const Wrapper = styled.footer`
  width: 100%;
  padding: 32px 0;
  background-color: ${({ theme }) => theme.colors.grey.dark};
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    padding: 48px 0;
  }
`;

export const Row = styled.div`
  font-family: ${({ theme }) => theme.font.family.body};
  color: ${({ theme }) => theme.colors.dark_white};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  a {
    z-index: 1;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 16px;
  }
`;
export const Text = styled.p`
  font-size: 14px;
  font-family: ${(props) => props.theme.font.family.body};
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.grey.light};
`;

export const Logo = styled(LogoSword)`
  width: 114px;
  height: 28px;
`;
