'use client';

import Link from 'next/link';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';

import { Wrapper, BannerBox, CloseButton } from './styles';
import CloseSvg from '@/public/assets/icons/circle-close.svg';
import getCookieData from '@/utils/getCookieParams';

const COOKIES_KEY_REMOVED = 'BANNER_REMOVED';
const { path, domain } = getCookieData();

const Banner = ({ bannerInfo, isWarning = false }) => {
  const { text, textLink, updatedAt } = bannerInfo;

  const [isVisible, setIsVisible] = useState(false);

  const bannerEl = useRef(null);

  const updateHeightCSSVar = useCallback((height) => {
    document.documentElement.style.setProperty('--banner-height', `${height}px`);
  }, []);

  const closeBanner = useCallback(() => {
    setCookie(COOKIES_KEY_REMOVED, updatedAt, getCookieData());
    setIsVisible(false);
    updateHeightCSSVar(0);
  }, [updatedAt, updateHeightCSSVar]);

  useEffect(() => {
    const removed = getCookie(COOKIES_KEY_REMOVED, { path, domain });

    if (!removed || (removed && removed !== updatedAt) || isWarning) setIsVisible(true);
  }, [isWarning]);

  useEffect(() => {
    if (isVisible) {
      const handleResize = () => {
        if (bannerEl.current) {
          updateHeightCSSVar(bannerEl.current.offsetHeight);
        }
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isVisible, updateHeightCSSVar]);

  if (!isVisible) {
    return null;
  }

  return (
    <Wrapper ref={bannerEl} $isWarning={isWarning}>
      <BannerBox>
        <div>
          <span>{text}</span>
          {textLink?.href && (
            <Link href={textLink.href} target="_blank" rel="noopener noreferrer">
              {textLink.label}
            </Link>
          )}
        </div>
        {!isWarning && (
          <CloseButton onClick={closeBanner}>
            <CloseSvg />
          </CloseButton>
        )}
      </BannerBox>
    </Wrapper>
  );
};

export default Banner;
