import styled from 'styled-components';

const Wrapper = styled.div`
  background-size: cover;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    height: 680px;
    justify-content: center;
    background: url('/assets/health-plans/header_desktop.jpg') no-repeat center center;
    background-size: cover;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 680px;
    justify-content: flex-end;
    background: url('/assets/health-plans/header_tablet.jpg') no-repeat center center;
    background-size: cover;
    padding-bottom: 80px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 568px;
    justify-content: flex-end;
    background: url('/assets/health-plans/header_mobile.jpg') no-repeat center center;
    background-size: cover;
    padding-bottom: 64px;
  }
`;

export default Wrapper;
