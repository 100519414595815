import MarkdownRichText from '@/components/shared/MarkdownRichText';
import { Title as DefaultTitle } from '@/components/core';

import styles from './title.module.css';

const Title = ({ children, extraLabel, titleSize = '4xl' }) => {
  return (
    <DefaultTitle as="h1" size={titleSize} className={styles.title}>
      <MarkdownRichText elements={['u']}>{children}</MarkdownRichText>
      {extraLabel && (
        <span className={styles.extraWrap}>
          <span className={styles.extra}>*</span>
        </span>
      )}
    </DefaultTitle>
  );
};

export default Title;
