'use client';

import { useLayoutEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import cn from 'classnames';

const DEFAULT_ANIMATION_SPEED_FACTOR = 3.3;

const Marquee = ({
  className,
  children,
  full,
  speedFactor = DEFAULT_ANIMATION_SPEED_FACTOR,
  invertedScroll,
  height,
}) => {
  const wrapperRef = useRef();
  const contentRef = useRef();

  const [width, setWidth] = useState();
  const [animate, setAnimate] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(0);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setWidth(contentRef.current.scrollWidth);
      setAnimationSpeed(contentRef.current.childElementCount * speedFactor);
    }
  }, [contentRef, children]);

  useLayoutEffect(() => {
    if (wrapperRef.current && width) {
      const handleResize = () => {
        if (width > wrapperRef.current.clientWidth) {
          setAnimate(true);
        } else {
          setAnimate(false);
        }
      };
      window.addEventListener('resize', handleResize);

      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [wrapperRef, width]);

  return (
    <Wrapper
      ref={wrapperRef}
      className={cn(className, { static: !animate })}
      $animate={animate}
      $height={height}
    >
      {!width || !animate ? (
        <Content ref={contentRef} $animate={animate} $full={full}>
          {children}
        </Content>
      ) : (
        <AnimatedContent
          animate={{
            x: invertedScroll ? [-width, 0] : [0, -width],
            transition: {
              x: {
                repeat: Infinity,
                repeatType: 'loop',
                duration: animationSpeed,
                ease: 'linear',
              },
            },
          }}
        >
          {children}
          {children}
        </AnimatedContent>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 32px;

  ${(props) =>
    !props.$animate &&
    css`
      &:after,
      &:before {
        display: none;
      }
    `};

  ${(props) =>
    props.$height &&
    css`
      height: ${props.$height}px;
    `}
`;

const contentCSS = css`
  position: absolute;
  float: left;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const Content = styled.div`
  ${contentCSS}
  ${(props) =>
    !props.$animate &&
    props.$full &&
    css`
      left: 50%;
      transform: translateX(-50%);
    `};

  ${(props) =>
    !props.$animate &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        left: 50%;
        transform: translateX(-50%);
      }
    `};
`;

const AnimatedContent = styled(motion.div)`
  ${contentCSS};
`;

export default Marquee;
