'use client';

import React, { useCallback, useContext, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import cn from 'classnames';

import NavigationContext from '@/contexts/navigation';
import NavItem from './NavItem';
import useButtonTracking from '@/utils/useButtonTracking';

const ListItem = ({ label, url, extraClass, component, dynamicNavbarLayout, button, ...rest }) => {
  const pathname = usePathname();
  const { setIsOpen, scrolled, hover } = useContext(NavigationContext);

  const isLink = useMemo(() => !!url, [url]);
  const Wrapper = useMemo(() => (isLink ? Link : React.Fragment), [isLink]);
  const Component = useMemo(() => component || 'a', [component]);
  const trackClick = useButtonTracking({ label, url, location: 'navbar' });

  const onClick = useCallback(() => {
    trackClick();

    setIsOpen(false);
  }, [trackClick, setIsOpen]);

  return (
    <NavItem
      className={cn(extraClass)}
      $active={url === pathname}
      $white={scrolled || hover}
      $dynamicNavbarLayout={dynamicNavbarLayout}
      $button={button}
    >
      <Wrapper {...(isLink ? { href: url, passHref: true } : {})} legacyBehavior>
        <Component data-testid="item-component" onClick={onClick} {...rest}>
          {label}
        </Component>
      </Wrapper>
    </NavItem>
  );
};

export default ListItem;
