import styled from 'styled-components';

const SocialWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 40px;
  & > a + a {
    margin-left: 13px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 0;
  }
`;

export default SocialWrapper;
