import PropTypes from 'prop-types';
import Wrapper from './Wrapper';

import { DIMENSION_NAMES } from '@/components/core/flexbox/config';

export const innerSpacerValues = { $md: 80, $sm: 64, $xs: 40 };

const Section = ({
  xs,
  sm,
  md,
  xl,
  xsTop,
  smTop,
  mdTop,
  color,
  firstColored,
  lastColored,
  children,
  noOverflow,
  spacerTop,
  spacerBottom = true,
  gradient,
  innerSpacer,
  ...rest
}) => {
  const defaultSpacings = spacerBottom
    ? { $xl: xl, $md: md, $sm: sm, $xs: xs }
    : { $xl: '0', $md: '0', $sm: '0', $xs: '0' };
  const spaces = {
    ...(innerSpacer ? innerSpacerValues : defaultSpacings),
  };

  if (firstColored || lastColored) {
    return (
      <Wrapper {...rest}>
        <Wrapper
          as="div"
          $xsTop={xsTop}
          $smTop={smTop}
          $mdTop={mdTop}
          $color={color}
          $noOverflow={noOverflow}
          $spacerTop={spacerTop || firstColored}
          $gradient={gradient}
          {...spaces}
        >
          {children}
        </Wrapper>
      </Wrapper>
    );
  }
  return (
    <Wrapper
      $xsTop={xsTop}
      $smTop={smTop}
      $mdTop={mdTop}
      $color={color}
      $noOverflow={noOverflow}
      $spacerTop={spacerTop}
      $gradient={gradient}
      {...spaces}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

const DimensionPropType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);
const DimensionPropTypes = DIMENSION_NAMES.slice(0, 3).reduce((propTypes, dimension) => {
  propTypes[dimension] = DimensionPropType;
  return propTypes;
}, {});

Section.propTypes = {
  ...DimensionPropTypes,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  xs: DimensionPropType,
  sm: DimensionPropType,
  md: DimensionPropType,
  xl: DimensionPropType,
  xsTop: DimensionPropType,
  smTop: DimensionPropType,
  mdTop: DimensionPropType,
  lastColored: PropTypes.bool,
  spacerTop: PropTypes.bool,
};

export default Section;
