import Image from 'next/image';
import styled from 'styled-components';

import { Container } from '@swordhealth/ui-corporate';
import { Section } from '@/components/core';
import { UpperText } from '@/components/core/Title';

export const Wrapper = styled.div`
  align-items: center;
  display: grid;
  min-height: calc(100vh - env(safe-area-inset-bottom) - var(--banner-height));
  position: relative;

  @media (min-height: 768px) {
    min-height: 768px;
  }

  &.header-dark {
    color: ${(props) => props.theme.colors.white};
  }

  &.header-with-extra-label {
    grid-template-rows: 1fr auto;
  }

  .bg-image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    img {
      object-fit: cover;
    }
  }
`;

export const StyledSection = styled(Section).attrs({ forwardedAs: 'div' })`
  display: grid;
  gap: 16px;
  margin: var(--header-height) 0;
  position: relative;

  .header-center & {
    justify-items: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .header-left & {
    justify-items: start;
    max-width: 480px;
  }

  h1,
  h2 {
    color: currentColor;
    white-space: pre-line;
  }
`;

export const StyledLabel = styled(UpperText)`
  padding-bottom: 0;

  .header-dark & {
    color: currentColor;
  }
`;

export const StyledLogo = styled(Image)`
  margin-bottom: 8px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: auto;
    margin-bottom: 16px;
    width: 82px;
  }
`;

export const ExtraLabel = styled(Container).attrs({ forwardedAs: 'footer' })`
  padding-bottom: 40px;

  .header-light & {
    color: ${(props) => props.theme.colors.grey.default};
  }
`;
