import cn from 'classnames';
import { SectionHeader as UiSectionHeader } from '@swordhealth/ui-corporate';

import styles from './styles.module.css';

function SectionHeader({ content, oldLabel = false, ...rest }) {
  return <UiSectionHeader className={cn({ [styles.oldLabel]: oldLabel })} {...content} {...rest} />;
}

export default SectionHeader;
