import styled from 'styled-components';

const SliderWrapper = styled.div`
  .slick-dots {
    padding: 0 24px;
    text-align: center;
    height: 10px;
    bottom: 0;
    li {
      &:first-child {
        margin-left: 0;
      }
      width: 10px;
      height: 10px;
      margin: 0 4px;
      top: -13px;
      &.slick-active {
        button {
          background-color: #1f222c;
        }
      }
      button {
        padding: 0;
        background-color: transparent;
        border: 2px solid #1f222c;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        opacity: 0.3;
        &:before {
          display: none;
        }
      }
    }
  }
`;

export default SliderWrapper;
