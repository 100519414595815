import styled, { css } from 'styled-components';

export const BannerBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: ${(props) => props.theme.colors.grey[800]};
  font-family: ${({ theme }) => theme.font.family.body};
  letter-spacing: normal;

  div {
    width: 100%;
  }

  a {
    display: inline-flex;
    text-decoration: underline;
    font-weight: 700;
    padding-left: 0.2rem;
  }
`;
export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.default};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey.light};
  padding: 12px 36px;
  z-index: 1000;
  position: relative;

  ${(props) =>
    props.$isWarning &&
    css`
      background-color: ${(props) => props.theme.colors.negative.default};
      ${BannerBox} {
        color: ${(props) => props.theme.colors.white};
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 12px 24px;
  }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;

  svg,
  path {
    fill: ${(props) => props.theme.colors.grey[800]};
  }
`;
