'use client';

import { useContext, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@/components/core/Button';
import NavigationContext from '@/contexts/navigation';
import About from '@/public/assets/navigation/about.svg';
import AICare from '@/public/assets/navigation/ai-care.svg';
import Newsroom from '@/public/assets/navigation/newsroom.svg';
import Careers from '@/public/assets/navigation/careers.svg';
import Employers from '@/public/assets/navigation/employers.svg';
import HealthPlans from '@/public/assets/navigation/health-plans.svg';
import DigitalTherapy from '@/public/assets/navigation/thrive.svg';
import TheAcademy from '@/public/assets/navigation/academy.svg';
import OnCall from '@/public/assets/navigation/oncall.svg';
import Move from '@/public/assets/navigation/move.svg';
import Bloom from '@/public/assets/navigation/bloom.svg';
import HealthEquity from '@/public/assets/navigation/health-equity.svg';
import Predict from '@/public/assets/navigation/predict.svg';
import Resources from '@/public/assets/navigation/resources.svg';
import FAQs from '@/public/assets/navigation/faqs.svg';
import Atlas from '@/public/assets/navigation/atlas.svg';
import Foundation from '@/public/assets/navigation/foundation.svg';

import Item from '../Item';
import ListItem from './Item';
import SuperListItem from '../SuperList';

const MenuList = ({ dynamicNavbarLayout, withBanner }) => {
  const { isOpen, enrollUrl, demoUrl, scrolled, hover } = useContext(NavigationContext);
  const [openTab, setTabOpen] = useState(null);

  return (
    <NavListWrapper $isOpen={isOpen} $withBanner={withBanner}>
      <NavListBox>
        <NavList>
          <SuperListItem
            url="business"
            label="Business"
            dynamicNavbarLayout={dynamicNavbarLayout}
            open={openTab === 'business'}
            setOpen={() => setTabOpen('business')}
            close={() => setTabOpen(null)}
          >
            <Item
              icon={<Employers />}
              label="Employers"
              url="/business/employers"
              subLabel="Solutions that deliver for you and your employees."
              close={() => {
                setTabOpen(null);
              }}
            />
            <Item
              icon={<HealthPlans />}
              label="Health Plans"
              url="/business/health-plans"
              subLabel="Smarter health plans choose Sword."
              close={() => setTabOpen(null)}
            />
          </SuperListItem>

          <ListItem
            label="Individuals"
            url="/individuals"
            dynamicNavbarLayout={dynamicNavbarLayout}
          />

          <SuperListItem
            url="solutions"
            label="Solutions"
            dynamicNavbarLayout={dynamicNavbarLayout}
            open={openTab === 'solutions'}
            setOpen={() => setTabOpen('solutions')}
            close={() => setTabOpen(null)}
          >
            <Item
              icon={<DigitalTherapy />}
              label="Thrive"
              url="/solutions/thrive"
              subLabel="Best-in-class digital physical therapy from the comfort of home."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<TheAcademy />}
              label="Academy"
              url="/solutions/academy"
              subLabel="The highest quality physical health education and resources."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<OnCall />}
              label="On-Call"
              url="/solutions/on-call"
              subLabel="Access to on-demand clinical pain specialists."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<Bloom />}
              label="Bloom"
              url="/solutions/bloom"
              subLabel="The next generation of women's pelvic health care."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<Move />}
              label="Move"
              url="/solutions/move"
              subLabel="A whole-body solution for a pain-free tomorrow."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<Predict />}
              label="Predict"
              url="/solutions/predict"
              subLabel="Built to predict and avoid costs of unnecessary surgeries."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<Atlas />}
              label="Atlas"
              url="/solutions/atlas"
              subLabel="Global access to pain-fighting exercises and education."
              close={() => setTabOpen(null)}
            />
          </SuperListItem>

          <SuperListItem
            url="learn"
            label="Learn"
            dynamicNavbarLayout={dynamicNavbarLayout}
            open={openTab === 'learn'}
            setOpen={() => setTabOpen('learn')}
            close={() => setTabOpen(null)}
          >
            <Item
              icon={<Newsroom />}
              label="Newsroom"
              url="/newsroom"
              subLabel="Find company updates, product announcements and other news."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<Resources />}
              label="Resources"
              url="/resources"
              subLabel="Explore our clinical studies, webinars and publications."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<FAQs />}
              label="Frequently Asked Questions"
              url="/faqs"
              subLabel="Have questions? We've got answers."
              close={() => setTabOpen(null)}
            />
          </SuperListItem>

          <SuperListItem
            url="company"
            label="Company"
            dynamicNavbarLayout={dynamicNavbarLayout}
            open={openTab === 'company'}
            setOpen={() => setTabOpen('company')}
            close={() => setTabOpen(null)}
            lastItem={true}
          >
            <Item
              icon={<About />}
              label="About"
              url="/about-us"
              subLabel="Learn who we are and why we do what we do."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<AICare />}
              label="AI Care"
              url="/ai"
              subLabel="Combining expert clinicians with human-centric AI to expand access to high-quality, personalized care."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<Foundation />}
              label="Foundation"
              url="/foundation"
              subLabel="Our social responsibility arm and how we are removing barriers for underserved communities."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<HealthEquity />}
              label="Health Equity"
              url="/company/health-equity"
              subLabel="Building a diverse and equitable future for everyone."
              close={() => setTabOpen(null)}
            />
            <Item
              icon={<Careers />}
              label="Careers"
              url="/company/careers"
              subLabel="Find openings and opportunities to join us on our mission."
              close={() => setTabOpen(null)}
            />
          </SuperListItem>

          <ActionItems data-testid="action-items">
            <ListItem
              label="Enroll"
              url={enrollUrl}
              component={Button}
              extraClass="enroll"
              dynamicNavbarLayout={dynamicNavbarLayout}
              button
              variant="secondary"
              size="sm"
              data-cta="enroll"
            />
            <ListItem
              label="Contact Sales"
              url={demoUrl}
              component={Button}
              extraClass="request-demo"
              dynamicNavbarLayout={dynamicNavbarLayout}
              button
              variant={scrolled || hover || !dynamicNavbarLayout ? 'primary' : 'white'}
              size="sm"
              data-cta="request-demo"
            />
          </ActionItems>
        </NavList>
      </NavListBox>
    </NavListWrapper>
  );
};

const NavListWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    ${(props) =>
      props.$withBanner &&
      css`
        height: calc(100vh - 47px);
      `};
    background-color: #fff;
    opacity: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    pointer-events: none;

    ${(props) =>
      props.$isOpen &&
      css`
        pointer-events: all;
        opacity: 1;
        z-index: 100;
      `}
  }
`;

const NavListBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    justify-content: flex-start;
    padding-top: 60px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    padding-bottom: 145px;
    position: relative;

    &:before {
      position: fixed;
      content: '';
      bottom: 125px;
      left: 0;
      right: 0;
      height: 20px;
      box-shadow: rgb(233 220 211 / 40%) 0 -2px 10px -2px;
      background-color: white;
      z-index: 10;
    }
  }
`;

const NavList = styled.div`
  display: inline-flex;
  list-style: none;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    justify-content: start;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: block;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 140px;
  }
`;

const ActionItems = styled.div`
  display: contents;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    box-shadow: rgb(233 220 211 / 40%) 0 -2px 10px -2px;
    background-color: white;
    z-index: 10;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    flex-direction: column;
  }
`;

export default MenuList;
