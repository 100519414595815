import React, { useContext, useEffect, useRef } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import styled from 'styled-components';
import cn from 'classnames';

import NavigationContext from '@/contexts/navigation';
import { Button } from '@/components/core/Button';
import { Container } from '@/components/core/flexbox';

import Hamburguer from './Burguer';
import LogoButton from './Logo';
import List from './List';
import useButtonTracking from '@/utils/useButtonTracking';
import { getCookie } from 'cookies-next';
import getCookieData from '@/utils/getCookieParams';

const BUTTON_TEXT = 'Contact Sales';

const COOKIES_KEY_REMOVED = 'BANNER_REMOVED';
const { path, domain } = getCookieData();

const Navigation = ({ fixed, dynamicNavbarLayout, withoutMenu = false, bannerActive }) => {
  const { scrolled, isOpen, hover, setHover, demoUrl } = useContext(NavigationContext);

  const removed = useRef(null);
  const trackClick = useButtonTracking({ label: BUTTON_TEXT, url: demoUrl, location: 'navbar' });

  useEffect(() => {
    removed.current = getCookie(COOKIES_KEY_REMOVED, { path, domain });
  }, []);

  return (
    <RemoveScroll enabled={isOpen}>
      <MenuWrapper
        className={cn({ fixed, open: isOpen, white: withoutMenu ? false : scrolled || hover })}
      >
        <Container>
          <Menu
            {...(withoutMenu
              ? {}
              : {
                  onMouseEnter: () => setHover(true),
                  onMouseLeave: () => setHover(false),
                })}
          >
            <MenuItem data-testid="menu-item">
              <LogoButton dynamicNavbarLayout={dynamicNavbarLayout} />
            </MenuItem>
            {!withoutMenu && (
              <>
                <MenuItem data-testid="menu-item">
                  <List
                    dynamicNavbarLayout={dynamicNavbarLayout}
                    withBanner={bannerActive && !scrolled && !removed.current}
                  />
                </MenuItem>
                <MenuItem data-testid="menu-item">
                  <Hamburguer dynamicNavbarLayout={dynamicNavbarLayout} />
                </MenuItem>
                <RequestDemoButton>
                  <Button
                    data-testid="request-demo"
                    href={demoUrl}
                    variant={scrolled || !dynamicNavbarLayout ? 'primary' : 'white'}
                    size="sm"
                    onClick={trackClick}
                    data-cta="request-demo"
                  >
                    {BUTTON_TEXT}
                  </Button>
                </RequestDemoButton>
              </>
            )}
          </Menu>
        </Container>
      </MenuWrapper>
    </RemoveScroll>
  );
};

export default Navigation;

export const MenuWrapper = styled.header`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: all 0.3s ease;

  &.white {
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: rgba(233, 220, 211, 0.4) 0 2px 10px 0;
  }

  &.fixed {
    position: fixed;
    top: 0;
    min-height: 80px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      min-height: 60px;
    }
  }

  &.open {
    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      border-bottom: 1px solid rgba(31, 34, 44, 0.1);
    }
  }
`;

const RequestDemoButton = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_md}px) {
    display: unset;
    position: absolute;
    content: '';
    top: 10px;
    right: 84px;
    z-index: 10010;
    opacity: 1;
    transition: opacity 0.3s ease;

    ${MenuWrapper}.open & {
      opacity: 0;
      pointer-events: none;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${(props) => props.theme.font.family.heading};
`;

const MenuItem = styled.div`
  &:first-child {
    order: 1;
    width: 15%;
    padding: 19px 0;
  }

  &:nth-child(2) {
    order: 2;
    width: 85%;
  }

  &:nth-child(3) {
    order: 3;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    line-height: 40px;
    &:nth-child(3) {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 16px 0;
    z-index: 10000;
    height: 60px;
    &:first-child {
      width: 50%;
      z-index: 10000000;
    }

    &:nth-child(2) {
      width: 50%;
    }
  }
`;
