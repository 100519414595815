import styled from 'styled-components';

const Copyright = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  white-space: pre-wrap;
  margin-top: 40px;
  margin-bottom: 27px;
  opacity: 0.6;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    position: absolute;
    white-space: normal;
    top: 86px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    top: unset;
    bottom: 0;
    white-space: normal;
    margin-top: 0;
    margin-bottom: 48px;
  }
`;

export default Copyright;
