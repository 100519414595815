'use client';

import { useCallback, useContext, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { usePathname } from 'next/navigation';
import cn from 'classnames';

import NavItem from '../List/NavItem';
import Dropdown, { DropdownBox } from './Dropdown';
import { Box } from '@/components/core/Navigation/Item';

import Arrow from '@/public/assets/icons/arrow.svg';

import NavigationContext from '@/contexts/navigation';

const SuperList = ({
  label,
  children,
  dynamicNavbarLayout,
  url,
  open,
  setOpen,
  close,
  lastItem = false,
}) => {
  const listEl = useRef(null);
  const buttonEl = useRef(null);
  const svg = useRef({ width: 0, height: 0, path: '', x: 0, y: 0 });
  const pathname = usePathname();
  const { scrolled, hover, isOpen } = useContext(NavigationContext);

  const URL = pathname.split('/');
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    // Draw a shape between the button and the dropdown
    // To prevent the dropdown from closing when the mouse is over the shape
    function drawExitShape() {
      if (!listEl.current || !buttonEl.current) return;

      const buttonBox = buttonEl.current.getBoundingClientRect();
      const listBox = listEl.current.getBoundingClientRect();

      svg.current.width = listBox.width;
      svg.current.height = buttonBox.height + listBox.height;
      svg.current.x = -buttonBox.x;
      svg.current.y = -buttonBox.y;

      // use a quadratic bezier curve for the right side of the shape
      let rightSide = `Q ${buttonBox.x + buttonBox.width},${listBox.y} ${
        buttonBox.x + buttonBox.width
      },${buttonBox.y + buttonBox.height / 3}`;

      // use a straight line for the last item since there is no danger of overlapping
      if (lastItem) {
        rightSide = `L ${buttonBox.x + buttonBox.width},${buttonBox.y}`;
      }

      svg.current.path = `M ${buttonBox.x},${buttonBox.y + buttonBox.height / 3}
      Q ${buttonBox.x},${listBox.y} ${listBox.x},${listBox.y}
      h ${listBox.width}
      ${rightSide}
      Z  
      `;
    }

    drawExitShape();

    window.addEventListener('resize', drawExitShape);

    () => window.removeEventListener('resize', drawExitShape);
  }, [buttonEl, lastItem, listEl, scrolled]);

  const cleanupTimeout = useCallback(
    (e) => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      close(e);
    },
    [close],
  );

  const delayOpening = useCallback(
    (event) => {
      let latestMouseCoordinates = { x: 0, y: 0 };

      function updateMouseCoordinates(e) {
        latestMouseCoordinates.x = e.clientX;
        latestMouseCoordinates.y = e.clientY;
      }

      window.addEventListener('mousemove', updateMouseCoordinates);

      if (timeoutIdRef.current) {
        cleanupTimeout();
      }

      timeoutIdRef.current = setTimeout(() => {
        window.removeEventListener('mousemove', updateMouseCoordinates);

        const eleBounds = event.target.getBoundingClientRect();

        if (
          latestMouseCoordinates.x >= eleBounds.left &&
          latestMouseCoordinates.x <= eleBounds.right &&
          latestMouseCoordinates.y >= eleBounds.top &&
          latestMouseCoordinates.y <= eleBounds.bottom
        ) {
          setOpen();
        }
      }, 200);
    },
    [cleanupTimeout, setOpen],
  );

  useEffect(() => {
    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, []);

  return (
    <SuperNavItem
      className={cn({ open })}
      $white={scrolled || hover || isOpen}
      $active={URL[1] === url}
      $dynamicNavbarLayout={dynamicNavbarLayout}
      $lastItem={lastItem}
      onMouseEnter={delayOpening}
      onMouseLeave={cleanupTimeout}
    >
      <Label ref={buttonEl} data-testid="superList-label" onClick={open ? close : setOpen}>
        <ExitPathSvg
          aria-hidden="true"
          height={svg.current.height}
          width={svg.current.width}
          style={{
            left: svg.current.x,
            top: svg.current.y,
          }}
        >
          <path d={svg.current.path} />
        </ExitPathSvg>

        <LabelContent>{label}</LabelContent>
        <Arrow className="arrow" aria-hidden="true" />
      </Label>
      <Dropdown ref={listEl} open={open}>
        {children}
      </Dropdown>
    </SuperNavItem>
  );
};

const LabelContent = styled.span`
  opacity: 1;
  transition: opacity 0.3s ease;
  pointer-events: none;
`;

const Label = styled.div`
  position: relative;

  .arrow {
    margin-left: 10px;
    pointer-events: none;
    transform: rotate(90deg);
    transition: all 0.3s ease;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    .arrow {
      path {
        fill: ${(props) => props.theme.colors.white};
      }
    }

    &:hover {
      width: unset;
      .arrow {
        transform: rotate(-90deg);
        path {
          fill: ${(props) => props.theme.colors.grey.dark};
        }
      }
    }

    &:after {
      position: absolute;
      content: '';
      bottom: -23px;
      left: 0;
      right: 0;
      height: 2px;
      width: 0;
      background-color: ${(props) => props.theme.colors.grey.dark};
      transition: all 0.3s ease;
      z-index: 1000000;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    line-height: 24px;
    .arrow {
      margin-top: 8px;
      transform: scale(1.2) rotate(90deg);
      path {
        fill: ${(props) => props.theme.colors.grey.dark};
      }
    }
    ${(props) =>
      props.$open &&
      css`
        ${LabelContent} {
          opacity: 0.7;
        }
        .arrow {
          opacity: 1;
          transform: scale(1.2) rotate(-90deg);
        }
      `};
  }
`;

const SuperNavItem = styled(NavItem)`
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    ${(props) =>
      props.$white &&
      css`
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      `};

    &.open {
      ${DropdownBox} {
        opacity: 1;
        z-index: 100000;
        pointer-events: all;
        transform: translateY(0);
      }

      ${Label} {
        .arrow {
          transform: rotate(-90deg);
          path {
            fill: ${(props) => props.theme.colors.grey.dark};
          }
        }
        &:after {
          width: 105%;
        }
      }
      ${Box} {
        transform: translateY(0);
      }
    }
  }

  ${(props) =>
    (!props.$dynamicNavbarLayout || props.$white) &&
    css`
      ${Label} {
        .arrow {
          path {
            fill: ${(props) => props.theme.colors.grey.dark};
          }
        }
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    position: relative;
    text-align: left;
    padding-left: 36px;
    padding-right: 36px;
    &:before {
      left: 32px;
      right: 32px;
    }

    &.open {
      ${Box} {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    padding: 24px;
    &:before {
      left: 24px;
      right: 24px;
    }
  }
`;

const ExitPathSvg = styled.svg`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  fill: rgba(0, 0, 0, 0);
  z-index: 1000000;

  .open & {
    path {
      pointer-events: auto;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

export default SuperList;
