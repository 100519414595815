import styled from 'styled-components';

import { Col } from '@/components/core/flexbox';

const LinksCol = styled(Col)`
  padding-top: 88px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 88px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 48px;
  }
`;

export default LinksCol;
