import styled, { css } from 'styled-components';

export const GAP = 136;
export const GAP_SM = 104;
export const GAP_XS = 64;

const Wrapper = styled.section`
  padding: 0 0 ${GAP}px;

  ${(props) =>
    props.$color &&
    css`
      background-color: ${props.$color};
    `};

  ${(props) =>
    props.$gradient &&
    css`
      background: ${props.$gradient};
    `};

  ${(props) =>
    props.$md &&
    css`
      padding-bottom: ${props.$md}px;
    `};

  ${(props) =>
    props.$spacerTop &&
    css`
      padding-top: ${(props) => (props.$mdTop ? props.$mdTop : GAP)}px;
    `};

  ${(props) =>
    props.$noOverflow &&
    css`
      overflow: clip;
      position: relative;
    `};

  @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    ${(props) =>
      props.$xl &&
      css`
        padding-bottom: ${props.$xl}px;
      `};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: ${GAP_SM}px;

    ${(props) =>
      props.$sm &&
      css`
        padding-bottom: ${props.$sm}px;
      `};

    ${(props) =>
      props.$spacerTop &&
      css`
        padding-top: ${(props) => (props.$smTop ? props.$smTop : GAP_SM)}px;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: ${GAP_XS}px;

    ${(props) =>
      props.$xs &&
      css`
        padding-bottom: ${props.$xs}px;
      `};

    ${(props) =>
      props.$spacerTop &&
      css`
        padding-top: ${(props) => (props.$xsTop ? props.$xsTop : GAP_XS)}px;
      `};
  }
`;

export default Wrapper;
