import styled, { css } from 'styled-components';
import Button from './Button';
import Chevron from '@swordhealth/ui-corporate/icons/system/chevron-right-2px.svg';

const StyledButton = styled(Button)`
  ${(props) =>
    props.$primary &&
    css`
      --button-color: ${(props) => props.theme.colors.primary.default};
    `};

  .chevron {
    height: auto;
    transition: transform 0.3s ease;
    transform: translateY(0.05em);
    width: 14px;
  }

  ${(props) =>
    props.$isLeft &&
    css`
      .chevron {
        order: -1;
        transform: translateY(0.05em) scaleX(-1);
      }

      &:hover,
      .linkButtonGroup:hover & {
        .chevron {
          transform: translateY(0.05em) scaleX(-1) translateX(3px);
        }
      }
    `};

  ${(props) =>
    !props.$isLeft &&
    css`
      &:hover,
      .linkButtonGroup:hover & {
        .chevron {
          transform: translateY(0.05em) translateX(3px);
        }
      }
    `};
`;

function LinkButton({ children, ...props }) {
  return (
    <StyledButton {...props} variant="text">
      {children}
      <Chevron className="chevron" />
    </StyledButton>
  );
}

export default LinkButton;
