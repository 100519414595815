import styled from 'styled-components';
import { Paragraph } from '../Paragraph';

const StyledSubtitle = styled(Paragraph).attrs({ forwardedAs: 'h2' })`
  max-width: 480px;
  margin-bottom: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 300px;
  }
`;

export default StyledSubtitle;
