'use client';

import { ArticleJsonLd, BreadcrumbJsonLd } from 'next-seo';
import { usePathname } from 'next/navigation';
import { getImgData } from '@/utils/images';
import moment from 'moment/moment';
import { memo } from 'react';

const BlogSEO = ({ post, postType }) => {
  const pathname = usePathname();
  const { title, publishedOn, publishedAt, updatedAt } = post;
  const featuredImage = getImgData(post?.featuredImage);

  const seo = post.seo
    ? {
        ...post.seo,
        image: getImgData(post?.seo?.image)?.url,
      }
    : {
        title: post.seoTitle ? post.seoTitle : title,
        description: post.seoDescription ? post.seoDescription : title,
        image: featuredImage?.url,
      };

  const publishedDate = moment(publishedOn || publishedAt);
  const updatedAtMoment = moment(updatedAt);
  const updatedDate = updatedAtMoment.isAfter(publishedDate) ? updatedAtMoment : publishedDate;

  return (
    <>
      <BreadcrumbJsonLd
        useAppDir={true}
        itemListElements={[
          {
            position: 1,
            name: postType.label,
            item: `${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/${postType.url}`,
          },
          {
            position: 2,
            name: seo.title,
            item: `${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/${postType.url}/${pathname}`,
          },
        ]}
      />

      <ArticleJsonLd
        useAppDir={true}
        url={`${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/${postType.url}/${pathname}`}
        title={seo.title}
        images={[seo.image]}
        datePublished={publishedDate.format()}
        dateModified={updatedDate.format()}
        authorName="Sword Health"
        publisherLogo={`${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}/logo-colored.svg`}
        description={seo.description}
      />
    </>
  );
};

export default memo(BlogSEO);
