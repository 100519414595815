import MarkdownRichText from '@/components/shared/MarkdownRichText';
import { Paragraph as UIParagraph } from '@swordhealth/ui-corporate';

function Paragraph({
  as = 'div',
  children,
  className,
  forwardedAs,
  isMarkdown = true,
  markdownProps = {},
  size = 'md',
  ...rest
}) {
  return (
    <UIParagraph as={as} forwardedAs={forwardedAs} size={size} className={className} {...rest}>
      {isMarkdown ? <MarkdownRichText {...markdownProps}>{children}</MarkdownRichText> : children}
    </UIParagraph>
  );
}

export default Paragraph;
