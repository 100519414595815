import Link from 'next/link';
import styled from 'styled-components';

const SocialLogo = styled(Link)`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.neutral.default};
  mix-blend-mode: multiply;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  transition: all 0.3s ease;
  svg {
    path {
      transition: all 0.3s ease;
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.grey.dark};
    svg {
      path {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

export default SocialLogo;
