import styled from 'styled-components';

import { Container } from '@/components/core/flexbox';

const Projects = () => (
  <Wrapper>
    <Container>
      <List>
        <Item label="Project_20812" href="/documents/projects/Project_20812.pdf" />
        <Item label="Project_23655" href="/documents/projects/Project_23655.pdf" />
        <Item label="Project_37596" href="/documents/projects/Project_37596.pdf" />
        <Item label="Project_46516" href="/documents/projects/Project_46516.pdf" />
        <Item label="Project_49222" href="/documents/projects/Project_49222.pdf" />
        <Item label="Project_QRAC" href="/documents/projects/Project_QRAC.pdf" />
        <Item label="Project_PRR62" href="/documents/projects/Project_PRR62_2024.pdf" />
      </List>
    </Container>
  </Wrapper>
);

const Item = ({ label, href }) => (
  <ItemWrapper>
    <a href={href} target="_blank" rel="noreferrer">
      {label}
    </a>
  </ItemWrapper>
);

export default Projects;

const Wrapper = styled.div`
  background: #edeae8;
  margin-bottom: 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 32px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 40px;
  }
`;

const List = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  padding-top: 18px;
  flex-wrap: wrap;
  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    justify-content: center;
  }
`;

const ItemWrapper = styled.div`
  font-size: 12px;
  line-height: 15px;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.grey[800]};
  cursor: pointer;
  opacity: 0.7;
`;
