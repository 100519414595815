import { Button } from '@/components/core/Button';

import { getFootnoteId } from '@/components/shared/footnotes';
import useButtonTracking from '@/utils/useButtonTracking';

import Container from './Container';
import Subtitle from './Subtitle';
import Title from './Title';
import Wrapper from './Wrapper';
import ExtraLabel from './ExtraLabel';
import UpperLabel from './UpperLabel';

const Header = ({
  className,
  content,
  footerReference,
  children,
  extraLabelAsterisk = true,
  childrenOutsideContainer,
  titleSize,
}) => {
  const { title, subtitle, href, upperLabel, extraLabel } = content;
  const trackClick = useButtonTracking({ label: href?.label, url: href?.href, location: 'header' });

  return (
    <Wrapper className={className}>
      <Container>
        {upperLabel && <UpperLabel>{upperLabel}</UpperLabel>}
        <Title extraLabel={extraLabel && extraLabelAsterisk} titleSize={titleSize}>
          {title}
        </Title>
        {subtitle && (
          <Subtitle markdownProps={{ elements: ['a'] }}>
            {`${subtitle}${footerReference ? `<sup><a href="#${getFootnoteId(footerReference)}">${footerReference}</a></sup>` : ''}`}
          </Subtitle>
        )}
        {href && (
          <Button href={href.href} onClick={trackClick}>
            {href.label}
          </Button>
        )}
        {extraLabel && (
          <ExtraLabel>{extraLabelAsterisk ? `*${extraLabel}` : extraLabel}</ExtraLabel>
        )}
        {!childrenOutsideContainer && children}
      </Container>
      {childrenOutsideContainer && children}
    </Wrapper>
  );
};

export default Header;
