import styled from 'styled-components';

import { UpperText } from '@/components/core/Title';

const UpperLabel = styled(UpperText)`
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  opacity: 1;
  padding-bottom: 48px;
`;

export const WhiteUpperLabel = styled(UpperText)`
  text-align: center;
  background: ${(props) => props.theme.colors.white};
  opacity: 1;
  padding: 8px 16px;
  border-radius: 1000px;
  color: ${(props) => props.theme.colors.grey.dark};
`;

export default UpperLabel;
