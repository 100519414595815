import { Paragraph } from '@swordhealth/ui-corporate';
import styled from 'styled-components';

const StyledHelpLabel = styled(Paragraph)`
  color: ${(props) => props.theme.colors.grey.default};
`;

const HelpLabel = ({ children, ...rest }) => (
  <StyledHelpLabel {...rest} size="xxs">
    {children}
  </StyledHelpLabel>
);

export default HelpLabel;
